import { FC, memo } from "react";

import {
  IHeaderLinkChild,
} from "apps/website/components/base/Button/Button.map";
import Text from "apps/website/components/base/Text/Text";

export interface INavigationItemChildrenAnchorTag {
  child: IHeaderLinkChild;
  onClick?: () => void;
}

const classes = "relative xl:block xl:pb-4 xl:hover:opacity-50 transition-opacity duration-300 opacity-100";

const NavigationItemChildrenAnchorTag: FC<INavigationItemChildrenAnchorTag> = ({ child, onClick }) => (
  <>
    { onClick ? (
      <button className={classes} onClick={onClick}>
        <Text tag="div" display="title" whitespace="noWrap">{ child.name }</Text>
      </button>
    ) : (
      <a href={child.to} className={classes}>
        <Text tag="div" display="title" whitespace="noWrap">{ child.name }</Text>
      </a>
    ) }
  </>
);

export default memo(NavigationItemChildrenAnchorTag);
