import {
  BreakpointSpan,
} from "apps/website/components/layout/Column/Column.map";

export type Columns = "[1,3]" | "[3,3]" | "[1,5]" | "[2,4]" | "[2,6]" | "[4,4]";

export type TitleSize = "default" | "sm" | "xs";
export type TitleDisplay = "default" | "subtitle";

export type IconWidth = "default" | 50 | 75 | 100 | 200 | 300;

export const columnSpanObjectMap: Record<Columns, BreakpointSpan> = {
  "[1,3]": { default: 12, lg: 4 },
  "[1,5]": { default: 12, lg: 2 }, // It's a 12 column grid
  "[2,4]": { default: 6, lg: 3 },
  "[2,6]": { default: 6, lg: 2 },
  "[3,3]": { default: 4, lg: 4 },
  "[4,4]": { default: 3, lg: 3 },
};

export const iconWidthClassMap: Record<IconWidth, string> = {
  default: "md:px-10 xl:px-14",
  50: "max-w-[50px]",
  75: "max-w-[75px]",
  100: "max-w-[100px]",
  200: "max-w-[200px]",
  300: "max-w-[300px]",
};
