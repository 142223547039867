import { FC, memo } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import {
  BreakpointSize,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Column from "apps/website/components/layout/Column/Column";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import { ILink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";
import { AvailableTag } from "apps/website/components/base/List/List.map";

import {
  Columns,
  columnSpanObjectMap,
  IconWidth,
  iconWidthClassMap,
  TitleSize,
} from "./IconTextListItem.map";

export interface IIconTextItem {
  image: IImageProps;
  title: string;
  titleSize?: TitleSize;
  body?: string;
  cta?: ILink;
  count?: number;
}

export interface IIconTextListItem {
  item: IIconTextItem;
  iconWidth?: IconWidth;
  columns?: Columns;
  listType?: AvailableTag;
}

const IconTextListItem: FC<IIconTextListItem> = ({ item, columns = "[2,4]", iconWidth = "default", listType }) => {

  const getTitleSize = (): BreakpointSize => {
    const titleSizeMap = {
      default: legacySizeCollectionMap.titleSm,
      sm: legacySizeCollectionMap.titleXs,
      xs: legacySizeCollectionMap.title2xs,
    };
    return titleSizeMap[item.titleSize ?? "default"];
  };
  return (
    <Column component="IconTextListItem" tag="li" key={item.title} spans={columnSpanObjectMap[columns]} align="center" justify="between">
      <div className="flex flex-col items-center max-w-full">
        <div className={iconWidthClassMap[iconWidth]}>
          <Image image={item.image} alt={item.title} />
        </div>
        { item.title && (
          <>
            <Spacer size="md" />
            <div className="flex justify-center items-center">
              <Text tag="h3" display={ "subtitle" } size={ getTitleSize() } align="center" className="flex items-center flex-1" whitespace={ { lg: "preWrap" } }>{ (listType === "ol" && item.count) ? (
                <span className="w-8 h-8 bg-black rounded-full text-white flex justify-center items-center mr-2">{ item.count }</span>
              ) : null }{ item.title }</Text>
            </div>
          </>
        ) }
        { item.body && (
          <>
            <Spacer size="md" />
            <Text align="center" whitespace={{ lg: "preWrap" }} size={legacySizeCollectionMap.base}>{ item.body }</Text>
          </>
        ) }
      </div>
      <div className="max-w-full">
        { item.cta && (
          <>
            <Spacer size="lg" />
            <Button to={item.cta.to} color={item.cta.color} design={item.cta.design}>{ item.cta.name }</Button>
          </>
        ) }
        <Spacer size="lg" className="lg:hidden"/>
      </div>
    </Column>
  );
};

export default memo(IconTextListItem);
