import { FC, memo } from "react";

import {
  IHeaderLinkChild,
} from "apps/website/components/base/Button/Button.map";
import Image from "apps/website/components/base/Image/Image";

export interface INavigationItemChildrenAnchorImage {
  child: IHeaderLinkChild;
}

const NavigationItemChildrenAnchorImage: FC<INavigationItemChildrenAnchorImage> = ({ child }) => (
  <>
    { (child.image && child.to) && (
      <a href={child.to} className="hidden xl:block xl:mb-4 aspect-square relative" tabIndex={-1}>
        <Image image={child.image} alt={child.name} cover/>
      </a>
    ) }
  </>
);

export default memo(NavigationItemChildrenAnchorImage);
