import { Align, SizeCollection } from "../../base/Text/Text.map";
import { BreakpointSpan, Span } from "../../layout/Column/Column.map";
import { Size as SpacerSize } from "../../layout/Spacer/Spacer.map";

export type Layout = "default" | "secondary" | "tertiary";
export type Size = "default" | "md" | "lg";

interface TextPartSize {
  title: SizeCollection;
  subtitle: SizeCollection;
  spacer: SpacerSize;
}

export const alignTextMap: Record<Layout, Align > = {
  default: "default",
  secondary: "center",
  tertiary: "center",
};

export const columnSpanMap: Record<Layout, BreakpointSpan | Span > = {
  default: { lg: 6 },
  secondary: { lg: 6 },
  tertiary: { lg: 6 },
};

export const sizeTextMap: Record<Size, TextPartSize> = {
  default: { title: "titleSm", subtitle: "body", spacer: "none" },
  md: { title: "titleMd", subtitle: "titleSm", spacer: "md" },
  lg: { title: "titleLg", subtitle: "titleMd", spacer: "lg" },
};
