import { FC } from "react";

import Grid from "../../layout/Grid/Grid";

import CondensedReviewListItem, {
  ICondensedReviewListItemProps,
} from "./CondensedReviewListItem/CondensedReviewListItem";

export interface ICondensedReviewListProps {
  items: ICondensedReviewListItemProps[];
}

const CondensedReviewList: FC<ICondensedReviewListProps> = ({ items }) => (
  <Grid tag="ul" component={CondensedReviewList.name}>
    { items.map((item, index) => (
      <CondensedReviewListItem
        {...item} key={item.body}
        offset={index === 0 ? items.length === 2 ? { xl: 3 } : undefined : undefined}
        xlSpans={items.length === 1 ? undefined : 4}
        className={index % 2 === 0 ? "-rotate-[0.5deg]" : "rotate-[0.5deg]"}
        hideTrustPilotStars={item.hideTrustPilotStars}
      />)) }
  </Grid>
);

export default CondensedReviewList;
