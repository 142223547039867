import { useCallback } from "react";

interface IGAEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

// Helper hook for pushing events to GA
export function useGA() {
  const trackEvent = useCallback((event: IGAEvent) => {
    if (typeof window !== undefined && window?.dataLayer) {
      try {
        window.dataLayer.push({
          event: "gaevent",
          category: event.category,
          action: event.action,
          label: event.label,
          gaValue: event.value,
        });
      } catch (e) {
        console.error(`Failed to track gaEvent ${event.category}:${event.action}:${event.label}:${event.value}`);
        console.error(e);
      }
    }
  }, []);

  return trackEvent;
}
