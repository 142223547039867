import { FC, FormEventHandler, ReactNode } from "react";
import { Method } from "axios";

export interface IForm {
  action: string;
  method?: Method;
  onSubmit: FormEventHandler<HTMLFormElement>;
  children: ReactNode;
  className?: string;
}

const Form: FC<IForm> = ({ action, method = "post", onSubmit, className, children }) => (
  <form data-component="Form" method={method} action={action} onSubmit={onSubmit} className={className}>
    { children }
  </form>
);

export default Form;
