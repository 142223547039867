export const isLocalPath = (path: string) => path.startsWith("/") && !path.startsWith("/manage-plan");

// Get the root path of a page, e.g. /products/cluck, the root path would be /products
export const getLocalPathRootPage = (path: string) => {
  let pathToReturn = path?.split("?")[0];
  pathToReturn = pathToReturn?.split("#")[0];
  return pathToReturn?.split("/")[1];
};

export const isActivePath = (linkPath: string, currentPath: string) => {
  if (!isLocalPath(linkPath)) return false;
  if (getLocalPathRootPage(linkPath) === getLocalPathRootPage(currentPath)) return true;
  return false;
};
