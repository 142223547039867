"use client";

import {
  FC,
  FormEventHandler,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";

import Text from "apps/website/components/base/Text/Text";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Form from "apps/website/components/form/Form/Form";
import Input from "apps/website/components/form/Input/Input";
import Button from "apps/website/components/base/Button/Button";
import {
  LoadingSpinner,
} from "apps/website/components/form/LoadingSpinner/LoadingSpinner";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import { useGA } from "apps/website/hooks/useGA";

import {
  alignTextMap,
  columnSpanMap,
  Layout,
  Size,
  sizeTextMap,
} from "./NewsletterSignUp.map";

export type State = "ready" | "processing" | "complete";

export interface INewsletterSignUp {
  title: string;
  titleId?: string;
  completeTitle: string;
  titleDisplay?: Display;
  body: string;
  bodyId?: string;
  completeBody: string;
  completeIcon?: IImageProps;
  buttonText: string;
  layout?: Layout;
  size?: Size;
  source?: string;
  hideLabels?: boolean;
}

const NewsletterSignUp: FC<INewsletterSignUp> = ({
  title, titleId, completeTitle, body, bodyId, completeBody, completeIcon, buttonText, layout = "default", size = "default", source, titleDisplay = "subtitle", hideLabels = false,
}) => {

  const [ firstName, setFirstName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ canSubmit, setCanSubmit ] = useState(false);
  const [ state, setState ] = useState<State>("ready");
  const [ error, setError ] = useState<string | undefined>(undefined);

  const trackEvent = useGA();

  useEffect(() => {
    setError(undefined);
    setCanSubmit(!!(firstName.trim().length) && !!(email.trim().length));
  }, [ firstName, email ]);

  const submitSignUpForm: FormEventHandler = useCallback((event) => {
    event.preventDefault();
    setState("processing");

    fetch(`${process.env.NEXT_PUBLIC_OLD_API_BASE_URL}/subscribeToNewsletter`, {
      method: "POST",
      body: JSON.stringify({ data: { email, firstName, source } }),
    })
      .then(() => setState("complete"))
      .catch(() => {
        setError("Something went wrong, please try again.");
        setState("ready");
      });

    trackEvent({
      category: "NEWSLETTER_SIGNUP",
      action: "EMAIL_SUBMITTED",
      value: email,
      label: `Source: ${source}`,
    });
  }, [ email, firstName, trackEvent ]);

  return (
    <Grid component="NewsletterSignUp">
      <Column>
        <Text id={titleId} tag="h2" display={titleDisplay} color="secondary" size={legacySizeCollectionMap[sizeTextMap[size].title]} align={alignTextMap[layout]}>
          { state === "ready" && (title) }
          { state === "processing" && ("Please wait") }
          { state === "complete" && (completeTitle) }
        </Text>
        <Spacer size={sizeTextMap[size].spacer} />
        <Text id={bodyId} display="subtitle" size={legacySizeCollectionMap[sizeTextMap[size].subtitle]} align={alignTextMap[layout]}>
          { state === "ready" && (body) }
          { state === "complete" && (completeBody) }
        </Text>
        <Spacer size={sizeTextMap[size].spacer} />
      </Column>
      <>
        { state === "ready" && (
          <>
            <Column>
              <Form action={`${process.env.NEXT_PUBLIC_OLD_API_BASE_URL}/subscribeToNewsletter`} onSubmit={submitSignUpForm}>
                <Grid>
                  <Column spans={columnSpanMap[layout]}>
                    <Suspense>
                      <Input
                        name="newsletter_first_name"
                        label="First name"
                        placeholder="First name"
                        fontSize="default"
                        onChange={(event) => setFirstName(event.target.value)}
                        hideLabel={hideLabels}
                      />
                    </Suspense>
                  </Column>
                  <Column spans={columnSpanMap[layout]}>
                    <Suspense>
                      <Input
                        name="newsletter_email"
                        type="email"
                        label="Email"
                        placeholder="Email"
                        fontSize="default"
                        onChange={(event) => setEmail(event.target.value)}
                        hideLabel={hideLabels}
                      />
                    </Suspense>
                  </Column>
                  <>
                    { error && (
                      <Column>
                        <Text display="subtitle" color="tertiary" align="center">{ error }</Text>
                      </Column>
                    ) }
                  </>
                  <Column justify="center" align="center">
                    <Button type="submit" disabled={!canSubmit}>{ buttonText }</Button>
                  </Column>
                </Grid>
              </Form>
            </Column>
          </>
        ) }
        { state === "processing" && (
          <Column justify="center" align="center">
            <LoadingSpinner color="white"/>
          </Column>
        ) }
        { (state === "complete" && completeIcon) && (
          <Column justify="center" align="center">
            <Spacer size="md" />
            <div className="max-w-[200px]">
              <Image image={completeIcon} alt={completeTitle} />
            </div>
            <Spacer size="md" />
          </Column>
        ) }
      </>
    </Grid>
  );
};

export default NewsletterSignUp;
