import { FC, Fragment } from "react";

import { IHeaderLink } from "apps/website/components/base/Button/Button.map";
import Button from "apps/website/components/base/Button/Button";

import { IHeaderAuth } from "../Header.map";
import HeaderNavigationItem from "../HeaderNavigationItem/HeaderNavigationItem";

import { menuClassMap, Menu } from "./HeaderAuthItems.map";

export interface IHeaderAuthItems {
  auth?: IHeaderAuth;
  authLinks: IHeaderLink[];
  guestLinks: IHeaderLink[];
  menu: Menu
  isHidden?: boolean;
  onClick?: () => void;
  className?: string;
}

const HeaderAuthItems: FC<IHeaderAuthItems> = (
  { auth, authLinks, guestLinks, menu, isHidden = false, onClick, className },
) => {

  const computedClasses = className ?? menuClassMap[menu];
  const tabIndex = isHidden ? -1 : 0;
  return (
    <>
      { !auth ? (
        <>
          { guestLinks.map((item) => (
            <Fragment key={item.id}>
              { item.type === "cta" ? (
                <Button key={item.id} to={item.to} size="fit-content" className={computedClasses}>{ item.name }</Button>
              ) : (
                <HeaderNavigationItem
                  key={item.id}
                  item={item}
                  className={computedClasses}
                  tabIndex={tabIndex}
                  isMenuAriaHidden={isHidden ? "true" : "false"}
                  onClick={onClick}
                />
              ) }
            </Fragment>
          )) }
        </>
      ) :
        (
          <>
            <HeaderNavigationItem
              item={{
                id: "",
                name: `Hi, ${auth.firstName}`,
                to: "/customer",
                children: authLinks.filter((authLink) => authLink.type !== "cta"),
                type: "authMenu",
              }}
              className={computedClasses}
              tabIndex={tabIndex}
              isMenuAriaHidden={isHidden ? "true" : "false"}
              onClick={onClick}
              disableToggle
            />
            { authLinks.filter((authLink) => authLink.type === "cta").map((authLink) => (
              <Button key={authLink.id} to={authLink.to} size="fit-content" className={computedClasses}>{ authLink.name }</Button>
            )) }
          </>
        ) }
    </>
  );
};

export default HeaderAuthItems;
