import {
  FC,
  memo,
  useEffect,
  useState,
} from "react";

import { IHeaderLink } from "apps/website/components/base/Button/Button.map";
import useWindowSize from "apps/website/hooks/useWindowSize";

import NavigationItem from "../../NavigationItem/NavigationItem";
import NavigationItemChildren from "../../NavigationItem/NavigationItemChildren/NavigationItemChildren";

export interface IHeaderNavigationItem {
  isMenuAriaHidden: "true" | "false";
  item: IHeaderLink;
  className?: string;
  tabIndex?: number;
  onClick?: () => void;
  disableToggle?: boolean;
}

const HeaderNavigationItem: FC<IHeaderNavigationItem> = (
  { item, isMenuAriaHidden, className, tabIndex, onClick, disableToggle = false },
) => {

  const { isActiveBreakpointBelow, windowSize } = useWindowSize();

  const [ isChildrenOpen, setIsChildrenOpen ] = useState(false);
  const [ isChildrenOpenMobile, setIsChildrenOpenMobile ] = useState(false);

  useEffect(() => {
    setIsChildrenOpenMobile(disableToggle);
  }, []);

  useEffect(() => {
    setIsChildrenOpen(isActiveBreakpointBelow("xl") && isChildrenOpenMobile);
  }, [ isChildrenOpen, isChildrenOpenMobile, windowSize ]);

  return (
    <>
      <NavigationItem
        key={item.name}
        item={item}
        tabIndex={tabIndex || (isMenuAriaHidden === "true" ? -1 : 0)}
        hasChildren={!!item.children?.length}
        handleShowChildren={(shouldShow) => setIsChildrenOpenMobile(shouldShow)}
        className={`group ${className}`}
        onClick={onClick}
        disableToggle={disableToggle}
      >
        <NavigationItemChildren
          childrenItems={item.children}
          isChildrenOpen={isChildrenOpen}
          type={item.type}
          disableToggle={disableToggle}
        />
      </NavigationItem>
    </>
  );
};

export default memo(HeaderNavigationItem);
